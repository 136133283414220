<template>
  <div class="envelope">
    <div class="describe">
      <div class="describe-title">活动简介</div>
      <div class="describe-cont">
        用户在包厢唱歌时，大屏幕会出现转动的红包，唱歌的声音会不断为红包蓄力，红包转满一圈会爆出金币。大屏会引导用户扫码领金币，金币可兑换场所和平台提供的礼品。
        <br>
        场所可自行配置优惠券、现金余额等礼品。
        <br>
        该活动通过“唱歌+领金币”的方式，提高门店优惠券的发放率和领取率，促进用户二次消费！
        <div class="cha" @click="chaClick()">查看示例</div>
      </div>
    </div>
    <div class="title">
      <div>活动设置</div>
      <el-button type="primary" @click="titleaddClick()">新增活动</el-button>
    </div>
    <div class="from">
      <el-form :inline="true" :model="formInline" class="demo-form-inline">
        <el-form-item label="品牌" v-if="user_id === 0">
          <el-select v-model="formInline.ping" placeholder="全部" @change="pingClick">
            <el-option :label="item.name" :value="item.id" v-for="(item,index) in pinglist" :key="index"></el-option>
          </el-select>
        </el-form-item>
        <el-form-item label="门店">
          <el-select v-model="formInline.men" placeholder="全部" @change="menClick">
            <el-option :label="item.name" :value="item.id" v-for="(item,index) in menData" :key="index"></el-option>
          </el-select>
        </el-form-item>
        <el-form-item label="投放状态">
          <el-select v-model="formInline.zhuang" placeholder="请选择" @change="zhuangClick">
            <el-option v-for="(item,index) in zhangData" :key="index" :label="item.name" :value="item.type"></el-option>
          </el-select>
        </el-form-item>
      </el-form>
    </div>
    <div class="table-cont">
      <el-table
          :max-height="heightmAC"
          :header-cell-style="headCalss"
          :data="listData"
          border
          style="width: 100%">
        <el-table-column
            prop="store_name" 
            align="center"
            label="门店">
        </el-table-column>
        <el-table-column
            prop="begin_date"
            align="center"
            label="开始时间">
        </el-table-column>
        <el-table-column
            prop="end_date"
            align="center"
            label="结束时间">
        </el-table-column>
        <el-table-column
            align="center"
            label="活动状态">
          <template slot-scope="scope">
            {{ scope.row.status == 0 ? '关闭' : '开启' }}
          </template>
        </el-table-column>
        <el-table-column
            prop="address"
            align="center"
            label="操作">
          <template slot-scope="scope">
            <div class="caoZUO">
              <span @click="chaClikc(scope.row)">查看</span>
              <span @click="eidtClick(scope.row)"> {{ scope.row.status == 0 ? '开启' : '关闭' }} </span>
            </div>
          </template>
        </el-table-column>
      </el-table>
    </div>
    <el-dialog
        title="新增金币活动"
        :visible.sync="dialogVisible"
        width="50%">
      <div>
        <el-form :model="ruleForm" :rules="rules" ref="ruleForm" label-width="80px" class="demo-ruleForm">
          <el-form-item label="活动门店" prop="men">
            <el-select v-model="ruleForm.men" placeholder="请选择活动门店" style="width:100%" multiple collapse-tags>
              <el-option :label="item.name" :value="item.id" v-for="(item,index) in menData" :key="index"></el-option>
            </el-select>
          </el-form-item>
          <div class="from-li">
            <el-form-item label="开始时间" prop="startTime">
              <el-date-picker
                  value-format="yyyy-MM-dd HH:mm:ss"
                  format="yyyy-MM-dd HH:mm:ss"
                  v-model="ruleForm.startTime"
                  type="datetime"
                  placeholder="开始时间">
              </el-date-picker>
            </el-form-item>
            <el-form-item label="结束时间" prop="endTime">
              <el-date-picker
                  v-model="ruleForm.endTime"
                  value-format="yyyy-MM-dd HH:mm:ss"
                  format="yyyy-MM-dd HH:mm:ss"
                  type="datetime"
                  placeholder="结束时间">
              </el-date-picker>
            </el-form-item>
          </div>
        </el-form>
        <el-tabs v-model="activeName">
          <el-tab-pane label="优惠券" name="1">
            <div>
              <el-button type="primary" size="mini" @click="addYou">添加优惠券</el-button>
              <ul class="tableUl">
                <li>
                  <div>优惠券名称</div>
                  <div>优惠券ID</div>
                  <div>所需金币</div>
                  <div>另需支付现金</div>
                </li>
                <li v-for="(item,index) in youData" :key="index">
                  <div>
                    <input type="text" placeholder="请输入名称" v-model="item.product_name">
                  </div>
                  <div>
                    <input type="text" placeholder="请输优惠券ID" v-model="item.product_code">
                  </div>
                  <div>
                    {{ item.coin_count }}
                  </div>
                  <div>
                    <input type="text" placeholder="请输入金额" v-model="item.pay_amount">
                  </div>
                </li>
              </ul>
            </div>
          </el-tab-pane>
          <el-tab-pane label="平台商品" name="2">
            <div style="color:red">平台商品无需商家提供，所以成本由平台承担</div>
            <ul class="tableUl">
              <li class="bnt">
                <div>勾选使用</div>
                <div>商品名称</div>
                <div>所需金币</div>
              </li>
              <li class="bnt" v-for="(item,index) in taiData" :key="index">
                <div>
                  <el-checkbox v-model="item.show" @change="changeClick(item,index)"></el-checkbox>
                </div>
                <div>{{ item.product_name }}</div>
                <div>{{ item.coin_count }}</div>
              </li>
            </ul>
          </el-tab-pane>
        </el-tabs>
        <div class="anniu">
          <el-button type="primary" @click="addBao">保存</el-button>
        </div>
      </div>

    </el-dialog>
    <!-- 视频弹出层 -->
    <div class="vCstyle" v-if="voShow">
      <div>
        <video loop autoplay=autoplay
               src="https://yc-zzyy.obs.cn-south-1.myhuaweicloud.com/om/canghai/coin_game_example.mp4">
          您的浏览器不支持Video标签。
        </video>
        <div @click="guan">关闭<i class="el-icon-circle-close"></i></div>
      </div>
    </div>
    <!-- 抽屉 -->
    <el-dialog
        title="详情"
        :visible.sync="drawer"
        width="50%">
      <div v-if="charOW != null" class="xiang">
        <div>
          <span>门店：{{ charOW.store_name }}</span>
        </div>
        <div>
          <span>开始时间：{{ chaLi.begin_date }}</span>
        </div>
        <div>
          <span>结束时间：{{ chaLi.end_date }}</span>
        </div>
        <ul class="tableUl">
          <li>
            <div>优惠券名称</div>
            <div>优惠券ID</div>
            <div>所需金币</div>
            <div>另需支付现金</div>
          </li>
          <li v-for="(item,index) in charOW.product_list.coupon" :key="index">
            <div>
              {{ item.product_name }}
            </div>
            <div>
              {{ item.product_code }}
            </div>
            <div>
              {{ item.coin_count }}
            </div>
            <div>
              {{ item.pay_amount }}
            </div>
          </li>
        </ul>
      </div>

    </el-dialog>
  </div>
</template>
<script>
// addRedRainPlan
import {
  brandList,
  storeList,
  getActivityList,
  editStatus,
  getProductList,
  addActivity,
  getActivityInfo
} from '../../api/index'

export default {
  data() {
    return {
      user_id: null,
      pinglist: [],
      menData: [],
      listData: [],
      // 查询
      formInline: {
        ping: '',
        men: '',
        zhuang: 1
      },
      voShow: false,
      // 分页
      // page:1,
      // total:0,
      // tableData:[],
      zhangData: [
        {
          type: 0,
          name: '停用'
        },
        {
          type: 1,
          name: '启用'
        }
      ],
      dialogVisible: false,
      ruleForm: {
        men: [],
        startTime: '',
        endTime: ''
      },
      rules: {
        men: [
          {required: true, message: '请选择门店', trigger: 'change'}
        ],
        startTime: [
          {required: true, message: '请选择开始时间', trigger: 'change'}
        ],
        endTime: [
          {required: true, message: '请选择结束时间', trigger: 'change'}
        ],
      },
      addData: null,
      taiData: [],
      activeName: '1',
      youData: [],
      heightmAC: window.innerHeight - 400,
      drawer: false,
      charOW: null,
      chaLi: null
    }
  },
  created() {
    this.user_id = JSON.parse(localStorage.getItem('user_id'))
    this._getProductList()
    //判断是否是管理员
    if (this.user_id == 0) {
      this._brandList()
    } else {
      this.menData = JSON.parse(localStorage.getItem('store_list'))
      this.formInline.ping = JSON.parse(localStorage.getItem('brand_id'))
      if (this.menData.length > 0) {
        this.formInline.men = this.menData[0].id
      }
      this._getActivityList()
    }
  },
  methods: {
    headCalss() {
      return 'background:#f5f7fa'
    },
    //获取优惠券
    _getProductList() {
      getProductList().then(er => {
        if (er.return_code == 200) {
          this.addData = er.data
          if (er.data.virtual_vip && er.data.virtual_vip.length > 0) {
            er.data.virtual_vip.map((ee) => {
              ee.show = true
            })
          }
          this.taiData = er.data.virtual_vip
        }
      })
    },
    eidtClick(row) {
      editStatus({
        brand_id: this.formInline.ping,
        store_id: this.formInline.men,
        activity_id: 5,
        status: row.status == 0 ? 1 : 0,
        store_activity_id: row.id
      }).then(er => {
        console.log(er)
        this.$message({
          message: "修改成功",
          type: "success",
        });
        this._getActivityList()
      })
    },
    chaClikc(row) {
      this.chaLi = row
      this.drawer = true
      getActivityInfo({
        store_activity_id: row.id
      }).then(Er => {
        let attr = Er.data.product_list.virtual_vip ? Er.data.product_list.virtual_vip : []
        if (Er.data.product_list.coupon && Er.data.product_list.coupon.length > 0) {
          Er.data.product_list.coupon.map(ec => {
            attr.push(ec)
          })
        }
        Er.data.product_list.coupon = attr
        console.log(Er.data)
        this.charOW = Er.data
      })
    },
    //获取品牌
    _brandList() {
      brandList().then(Er => {
        if (Er.data && Er.data.length > 0) {
          this.pinglist = Er.data
          this._storeList(Er.data[0].id)
          this.formInline.ping = Er.data[0].id
        }
      })
    },
    //获取门店
    _storeList(row) {
      storeList({
        brand_id: row
      }).then(Er => {
        if (Er.return_code == 200) {
          if (Er.data && Er.data.length > 0) {
            this.menData = Er.data
            this.formInline.men = Er.data[0].id
            this._getActivityList()
          } else {
            this.menData = []
          }
        }
      })
    },
    pingClick(val) {
      this._storeList(val)
    },
    menClick(val) {
      this.formInline.men = val
      this._getActivityList()
    },
    zhuangClick(val) {
      this.formInline.zhuang = val
      this._getActivityList()
    },
    //获取金币活动
    _getActivityList() {
      getActivityList({
        brand_id: this.formInline.ping,
        store_id: this.formInline.men,
        status: this.formInline.zhuang
      }).then(er => {
        this.listData = er.data.data
      })
    },
    // 新增
    titleaddClick() {
      this.dialogVisible = true
      if (this.$refs['ruleForm']) {
        this.$refs['ruleForm'].resetFields()
      }
      this.youData = []
      if(this.taiData.length>0 && this.taiData ){
          this.taiData.map(er =>{
            er.show = true
          })
      }
    },
    //保存
    addBao() {
      let _thsi = this
      this.$refs['ruleForm'].validate((valid) => {
        if (valid) {





          //转换门店id
          let text = ''
          this.ruleForm.men.map((Er, index) => {
            if (this.ruleForm.men.length - 1 == index) {
              text += Er
            } else {
              text += Er + ','
            }
          })
          //转换兑换产品
          let attr = []
          if (this.taiData.length > 0) {
            this.taiData.map(EC => {
              if (EC.show) {
                delete EC.show
                attr.push(EC)
              }
            })
          }
          let  a = true
          if (this.youData.length > 0) {
            this.youData.map((ec) => {
              if(ec.product_name == '' || ec.product_code == ''){
                a = false
              }else{
                 attr.push(ec)
              }
            })
          }
          console.log(a)

          if(a){
              let can = {
            activity_id: 5,
            brand_id: this.formInline.ping,
            store_id: text,
            begin_date: this.ruleForm.startTime,
            end_date: this.ruleForm.endTime,
            product_list: JSON.stringify(attr)
          }
          addActivity(can).then(er => {
            if (er.return_code == 200) {
              _thsi.$message({
                message: "添加成功",
                type: "success",
              });
              _thsi.dialogVisible = false
              _thsi._getActivityList()
            }
          })
          }else{
             _thsi.$message({
                  message: "当前优惠券不能为空",
                  type: "error",
                });
          }
        
        } else {
          return false;
        }
      });

    },
    addYou() {
      this.youData.push(
          {
            product_name: '',
            product_code: '',
            coin_count: this.addData.coupon_info.coin_count,
            pay_amount: 0,
            exchange_rules_id: this.addData.coupon_info.exchange_rules_id,
            type: this.addData.coupon_info.type
          }
      )
    },
    changeClick(item, index) {
      let arr = JSON.parse(JSON.stringify(this.taiData))
      console.log(arr[index].show)
      this.taiData = arr
    },
    //查看视频
    chaClick() {
      this.voShow = true
    },
    guan() {
      this.voShow = false
    }
  }
}
</script>

<style lang="scss" scoped>
.envelope {
  .describe {
    .describe-title {
      height: 40px;
      line-height: 40px;
      font-weight: bold;
    }

    .describe-cont {
      color: #909399;
      line-height: 20px;
      margin-bottom: 10px;
    }

    .cha {
      text-decoration: underline;
      color: #409EFF;
      margin-top: 10px;

      &:hover {
        cursor: pointer;
      }
    }
  }

  .title {
    height: 60px;
    display: flex;
    align-items: center;
    width: 100%;

    > div {
      &:nth-child(1) {
        margin-right: 10px;
      }
    }
  }

  .pageStype {
    display: flex;
    justify-content: flex-end;
    margin-top: 20px;
  }
}

.from {
  padding: 15px 0px;
}

.from-li {
  display: flex;
}

.tableUl {
  border-top: 1px solid #DCDFE6;
  border-right: 1px solid #DCDFE6;
  margin-top: 10px;

  > li {
    height: 40px;
    line-height: 40px;
    display: flex;

    > div {
      &:nth-child(3) {
        background: rgb(245, 247, 250);
      }

      width: 25%;
      border-left: 1px solid #DCDFE6;
      border-bottom: 1px solid #DCDFE6;
      display: flex;
      align-content: center;
      justify-content: center;
      padding: 0px 10px;

      > input {
        width: 95%;
        text-align: center;
        border: none;

        &:focus {
          outline: none; //边框不用border，用outline
        }
      }
    }
  }

  > .bnt {
    > div {
      width: 33.33%;
    }
  }
}

.anniu {
  margin-top: 20px;
}

.caoZUO {
  > span {
    color: #409EFF;

    &:nth-child(1) {
      margin-right: 10px;
    }

    &:hover {
      cursor: pointer;
    }
  }
}

.vCstyle {
  position: fixed;
  top: 0px;
  left: 0px;
  width: 100%;
  height: 100%;
  background: rgba(0, 0, 0, 0.5);
  z-index: 999;
  display: flex;
  align-items: center;
  justify-content: center;

  > div {
    width: 70%;
    height: 70%;

    > video {
      width: 100%;
      height: 70%;
      margin-bottom: 20px;
    }

    > div {
      color: #fff;
      margin: 0 auto;
      width: 300px;
      background: #409EFF;
      text-align: center;
      height: 50px;
      display: flex;
      align-items: center;
      justify-content: center;
      border-radius: 5px;

      > i {
        margin-left: 3px;
        margin-top: 2px;
      }

      &:hover {
        cursor: pointer;
      }
    }

  }
}

.xiang {
  line-height: 40px;
}
</style>